<template>
  <section class="section">
    <h4 class="title has-text-centered is-4">Patreon Sync Logs</h4>

    <sync-logs-list :logs="logs.data" :loading="loadingLogs"></sync-logs-list>

    <b-pagination
      class="mt-4"
      v-model="logs.current_page"
      :total="logs.total"
      :per-page="logs.per_page"
      range-before="3"
      range-after="3"
      order="is-centered"
      icon-pack="fas"
      @change="handlePageChange"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page">
    </b-pagination>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import SyncLogsList from '../../components/Patreon/SyncLogsList.vue';

export default {
  name: 'SyncLogs',
  components: { SyncLogsList },
  computed: {
    ...mapState('patreon', ['loadingLogs', 'logs']),
  },
  mounted() {
    this.loadLogs();
  },
  watch: {
    // eslint-disable-next-line func-names
    '$route.query': function () {
      this.loadLogs();
    },
  },
  methods: {
    loadLogs() {
      this.$store.dispatch('patreon/loadLogs', this.$route.query);
    },
    handlePageChange(pageNum) {
      const params = {
        ...this.$route.query,
        page: pageNum,
      };
      const queryString = Object.keys(params).map((key) => `${key}=${params[key] || ''}`).join('&');

      this.$router.push({ path: `/patreon/logs?${queryString}` });
    },
  },
};
</script>

<style>
.user-table {
  font-size: 12px;
}
.clickable-user {
  cursor: pointer;
  text-decoration: underline;
}
</style>
