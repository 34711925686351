<template>
  <b-table
    :data="logs"
    :striped="true"
    :hoverable="true"
    :loading="loading"
    class="mt-4 user-table"
  >
    <b-table-column field="created_at" label="Date" v-slot="props">
      <p>
        {{ props.row.created_at | dateStandard }}
      </p>
    </b-table-column>

    <b-table-column field="type" label="Action" v-slot="props">
      <b-field>
        <b-tag :type="classByType(props.row.type)">{{ props.row.type }}</b-tag>
      </b-field>
    </b-table-column>

    <b-table-column :visible="user === undefined" field="user_id" label="User" v-slot="props">
      <p v-if="props.row.user_id" @click="goToUser(props.row.user_id)" class="clickable-user">
        <strong>{{ props.row.user.name }}</strong>
        <br />
        {{ props.row.user.email }}
      </p>
    </b-table-column>

    <b-table-column field="email" label="Email" v-slot="props">
      <p>{{ props.row.email }}</p>
    </b-table-column>

    <b-table-column field="full_name" label="Name" v-slot="props">
      <p>{{ props.row.full_name }}</p>
    </b-table-column>

    <b-table-column field="tier_amount" label="Amount" v-slot="props">
      <p>
        <strong>${{ props.row.tier_amount / 100 }}</strong>
      </p>
    </b-table-column>

    <b-table-column field="patreon_id" label="Patreon ID" v-slot="props">
      <p>{{ props.row.patreon_id }}</p>
    </b-table-column>
  </b-table>

</template>

<script>
export default {
  name: 'SyncLogsList',
  props: {
    logs: Array,
    loading: Boolean,
    user: Object,
  },
  methods: {
    classByType(type) {
      switch (type.toLowerCase()) {
        case 'new':
          return 'is-success';

        case 'missing':
          return 'is-light';

        case 'amount-up':
          return 'is-info';

        case 'amount-down':
          return 'is-warning';

        case 'delete':
          return 'is-danger';

        default:
          return 'is-light';
      }
    },
    goToUser(userId) {
      this.$router.push({ path: `/users/${userId}` });
    },
  },
};
</script>

<style>
.user-table {
  font-size: 12px;
}
.clickable-user {
  cursor: pointer;
  text-decoration: underline;
}
</style>
